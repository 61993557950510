import React from "react"
import "../components/layout.scss"
import { SubpageLayout } from "../components/layout"
import SEO from "../components/seo"

import { init, track } from 'fbq'

const Implants = () => {

  const pixel = 'G-GENH92VF2T' 
  init(pixel)
    

    // const [serverState, setServerState] = useState({
    //   submitting: false,
    //   status: null
    // })
    // const handleServerResponse = (ok, msg, form) => {
    //   setServerState({
    //     submitting: false,
    //     status: [ ok, msg ]
    //   })
    //   if (ok) {
    //     form.reset()
    //   }
    // }

    // const handleOnSubmit = e => {
    //   e.preventDefault();
    //   const form = e.target;
    //   setServerState({ submitting: true });
    //   axios({
    //     method: "post",
    //     url: "https://getform.io/f/a4d2fd4d-0264-4a0b-a9c1-d4d70f92dd36",
    //     data: new FormData(form)
    //   })
    //     .then(r => {
    //       handleServerResponse(true, "Thank you. We will contact you to schedule an appointment.", form);
    //     })
    //     .catch(r => {
	  // console.log({r})
    //     //   handleServerResponse(false, r, form)
    //     })
    // }
   
  return (

  <SubpageLayout>
    <SEO title="Dental Implants - Complimentary Consultation" />
    <div className="container h-200">
      <div className="row h-100 align-items-left justify-content-left text-left">
        <div className="col-lg-6 col-md-8 col-sm-8 col-8 align-self-end" style={{marginTop: "100px"}}>
          <h3 className="text-uppercase text-white font-weight-bold" style={{paddingLeft: "15px"}}>
            Complimentary Consultation
          </h3>

        </div>
      </div>
    </div>
    <div className="container h-200">
    <div className="col-lg-6 col-md-8 col-sm-8 col-8 align-self-baseline">
      <div className="text-white-75 font-weight-light mb-5">
        
	{/* {(!serverState.status) ? ( */}
	{/* <div> */}
	<div align="left">
      
          <p>Call Now to schedule your complimentary consultation to see if Dental Implants are right for you!</p>
          
        </div>
	  
         <div className="col-md-8 mt-5">
           
           
        <a style={{color: 'white', fontSize: '1.25rem'}} href="tel:+1(801)397-2323" onClick={() => {track('Contact')}}>+1 (801) 397-2323</a>

            {/* <form onSubmit={handleOnSubmit} className='info-request-form'>
              <input type="text" name="name" placeholder="Your Name" />
	      <input type="text" rel="phone" name="phone" placeholder="Your Phone #" />
              <input type="email" name="email" placeholder="Your Email" />
              {/* <input type="text" multiple="true"  name="message" placeholder="Your Message" /> */}
              {/* <button type="submit">Send</button> */}
	    {/* </form> */} 

	{/* </div> */}
	</div>
	{/* ) : (
	<div>{serverState.status}</div>
	)} */}
      </div>
      </div>
      
      
    </div>
    
    
  </SubpageLayout>
)}

export default Implants
